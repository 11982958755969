/* parallax.css */
.parallax {
    /* The image used */
    background-image: url('../assets/images/nextgen-banner-1.webp');
  
    /* Set a specific height */
    height: 500px;
  
    /* Create the parallax effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
    /* Optional: You can add a gradient overlay */
    /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://example.com/your-background-image.jpg'); */
  }
  